import { ECardType } from 'models/card/CardType'
import { EContainerState, EContainerType } from 'models/container/enumeration'
import {
  EContentOriginSystem,
  EContentOwner,
  EContentSource,
  EContentState,
  EContentType,
  EContentTypology,
  ERecordType,
  ETenant,
  TContentSubtypology,
} from 'models/content/enumeration'
import { TFilterName } from 'modules/filters-panel/filters-panel'

export interface ITag {
  value: string
}

export interface SearchServiceOrchestrationContainer extends SearchServiceOrchestrationItem {
  objectType: ESearchServiceOrchestrationItemObjectType.CONTAINER
  recordType: ERecordType
  type: EContainerType
  name: string
  source: EContentSource
  status: EContainerState
  modificationDate: string
}

interface ITenantMapping {
  owner: EContentOwner
  tenant: ETenant
}

export interface SearchServiceOrchestrationContent extends SearchServiceOrchestrationItem {
  objectType: ESearchServiceOrchestrationItemObjectType.CONTENT
  recordType: ERecordType
  type: EContentType
  typology: EContentTypology
  name: string
  owner: EContentOwner
  source: EContentSource
  status: EContentState
  originSystem: EContentOriginSystem
  modificationDate: string
  imageUrl: string
  showName: string
  seasonNumber: string
  episodeNumber: string
  activationDate: string
  expirationDate: string
  tenantMappings?: ITenantMapping[]
  subTypology?: TContentSubtypology
}

export enum ESearchServiceOrchestrationItemObjectType {
  CONTENT = 'OrchestrationContent',
  CONTAINER = 'OrchestrationContainer',
}

interface SearchServiceOrchestrationItem {
  id: string
  slug: string
  owner?: EContentOwner
}

export interface SearchServiceResultOrchestrationShows {
  results: SearchServiceOrchestrationContent[]
}

export interface SearchServiceResultOrchestration {
  results: TSearchServiceResultOrchestration[]
  totalCount: number
}

export type TSearchServiceResultOrchestration = SearchServiceOrchestrationContent | SearchServiceOrchestrationContainer

export interface SearchServiceResultOrchestrationTags {
  tags: string[]
}

export interface IShow {
  activationDate: number | string
  expirationDate: number | string
  imageUrl: string
  modificationDate: string
  name: string
  objectID: string
  objectType: ECardType
  originSystem: string
  recordType: string
  source: string
  status: string
  type: string
  owner: string
}

export enum ESearchServicePathSegment {
  ORCHESTRATION = `orchestration`,
  ORCHESTRATION_TAGS = `orchestration/tags`,
}

export interface ISearchServiceFilter {
  name: TFilterName
  type: ESearchServiceOrchestrationFilterType
}

export enum ESearchServiceOrchestrationFilterType {
  TYPE = 'recordType',
  ORIGIN_SYSTEM = 'originSystem',
  SOURCE = 'source',
  STATE = 'status',
  TAGS = 'tags',
  OWNER = 'owner',
  LINKED_CONTENT = 'containerWithLinkedContent',
  RECOMMANDABLE = 'recommendableContainer',
  EMBARGO = 'embargo',
  EXPIRED = 'expired',
  ID = 'id',
  INTERESTS = 'interests',
}
