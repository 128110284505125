import config from 'config'
import { ECardType } from 'models/card/CardType'
import { IContentCard } from 'models/card/ContentCard'
import {
  IContainerCarousel,
  IContainerChannel,
  IContainerPage,
  IContainerSource,
  IContainerVideoSeason,
  IContainerVideoShow,
} from 'models/container/container'
import {
  EContainerState,
  EContainerSubTypeCarousel,
  EContainerSubTypePage,
  EContainerType,
  EContainerTypology,
} from 'models/container/enumeration'
import {
  IContent,
  IContentCarousel,
  IContentChannel,
  IContentFeed,
  IContentPage,
  IContentText,
  IContentVideo,
  IContentVideoParent,
  IContentVideoPreview,
  IContentVideoSeason,
  IMenuReference,
  IOriginNameRelationship,
  TContent,
  TRelationship,
} from 'models/content/content'
import {
  EContentBrightcoveGenres,
  EContentBrightcoveIllicoGenres,
  EContentOriginSystem,
  EContentOwner,
  EContentSource,
  EContentState,
  EContentType,
  EContentTypology,
  EExternalLinkSubTypology,
  EPageSubTypology,
  ERecordType,
  ERelationshipQualifier,
  ERelationshipType,
  EStorySubTypology,
  ETenant,
  EVideoParentSubTypology,
  EVideoSubTypology,
  TContentSubtypology,
  TSubtypology,
} from 'models/content/enumeration'
import { IAssociation } from 'models/detail/content/ContentAssociation'
import i18n from 'translations/i18n'

import { ISelectWrapperOption } from '../../components/selectwrapper/SelectWrapper'
import { ETabContext } from '../../redux/reducers/filters/filters.reducer'

const getContainerStatusFromContentState = (state: EContentState): EContainerState => {
  switch (state) {
    case EContentState.ACTIVE:
      return EContainerState.ACTIVE
    case EContentState.INCOMPLETE:
      return EContainerState.INCOMPLETE
    default:
      return EContainerState.ERROR
  }
}

export const getContentTypeOrSubType = (content: TContent): string => {
  if (content.type === EContentType.FEED_CONTENT) {
    return i18n.t(`recordType.${content.typology}_${content.subTypology}`)
  } else if (
    content.typology === EContentTypology.VIDEO_SHOW ||
    content.typology === EContentTypology.EXTERNAL_LINK ||
    (content.typology === EContentTypology.STORY && content.subTypology === EStorySubTypology.RECIPE)
  ) {
    return 'subTypology' in content && ContentService.getSubTypologyLabel(content.typology, content.subTypology)
  }
  return i18n.t(`recordType.${content.typology}`)
}

export const getContainerFromContentPage = (content: IContentPage): IContainerPage => ({
  id: content.containerId,
  name: content.name,
  publicTitle: content.publicTitle,
  type: EContainerType.PAGE,
  typology: EContainerTypology.PAGE,
  subType: content.containerSubType,
  subTypology: content.subTypology,
  status: getContainerStatusFromContentState(content.state),
  alias: content.containerAlias,
  aliasLink: content.containerAliasLink,
  createdBy: content.createdBy,
  creationDate: content.creationDate,
  modifiedBy: content.modifiedBy,
  modificationDate: content.modificationDate,
  version: content.version,
  slots: content.slots || [],
  canBeDeletedOrDuplicated: content.canBeDeletedOrDuplicated,
})

export const getContainerFromContentChannel = (content: IContentChannel): IContainerChannel => ({
  id: content.containerId,
  name: content.name,
  type: EContainerType.CHANNEL,
  typology: EContainerTypology.CHANNEL,
  status: getContainerStatusFromContentState(content.state),
  alias: content.containerAlias,
  aliasLink: content.containerAliasLink,
  createdBy: content.createdBy,
  creationDate: content.creationDate,
  modifiedBy: content.modifiedBy,
  modificationDate: content.modificationDate,
  version: content.version,
  slots: content.slots || [],
  canBeDeletedOrDuplicated: content.canBeDeletedOrDuplicated,
})

export const getContainerFromContentCarousel = (content: IContentCarousel): IContainerCarousel => ({
  id: content.containerId,
  name: content.name,
  type: EContainerType.CAROUSEL,
  typology: EContainerTypology.CAROUSEL,
  subType: content.containerSubType,
  subTypology: content.subTypology,
  status: getContainerStatusFromContentState(content.state),
  alias: content.containerAlias,
  createdBy: content.createdBy,
  creationDate: content.creationDate,
  modifiedBy: content.modifiedBy,
  modificationDate: content.modificationDate,
  version: content.version,
  slots: content.slots || [],
  canBeDeletedOrDuplicated: content.canBeDeletedOrDuplicated,
  mosaicRowsXS: content.mosaicRowsXS,
  mosaicRowsS: content.mosaicRowsS,
  mosaicRowsL: content.mosaicRowsL,
  recommendable: !content.disableRecommendation,
  excludedPlatforms: content.excludedPlatforms,
})

export const getContainerFromContentFeed = (content: IContentFeed): IContainerSource => ({
  id: content.containerId,
  name: content.name,
  type: EContainerType.SOURCE_FEED,
  typology: EContainerTypology.SOURCE_FEED,
  source: content.source,
  status: getContainerStatusFromContentState(content.state),
  alias: content.containerAlias,
  createdBy: content.createdBy,
  creationDate: content.creationDate,
  modifiedBy: content.modifiedBy,
  modificationDate: content.modificationDate,
  version: content.version,
  slots: content.slots || [],
  canBeDeletedOrDuplicated: content.canBeDeletedOrDuplicated,
})

export const getContainerFromVideoParent = (content: IContentVideoParent): IContainerVideoShow => ({
  id: content.containerId,
  name: content.name,
  publicTitle: content.publicTitle,
  type: EContainerType.VIDEO_SHOW,
  typology: EContainerTypology.VIDEO_SHOW,
  status: getContainerStatusFromContentState(content.state),
  alias: content.containerAlias,
  aliasLink: content.containerAliasLink,
  createdBy: content.createdBy,
  creationDate: content.creationDate,
  modifiedBy: content.modifiedBy,
  modificationDate: content.modificationDate,
  version: content.version,
  slots: content.slots || [],
  canBeDeletedOrDuplicated: content.canBeDeletedOrDuplicated,
})

export const getContainerFromVideoSeason = (content: IContentVideoSeason): IContainerVideoSeason => ({
  id: content.containerId,
  name: content.name,
  type: EContainerType.VIDEO_SEASON,
  typology: EContainerTypology.VIDEO_SEASON,
  status: getContainerStatusFromContentState(content.state),
  alias: content.containerAlias,
  aliasLink: content.containerAliasLink,
  createdBy: content.createdBy,
  creationDate: content.creationDate,
  modifiedBy: content.modifiedBy,
  modificationDate: content.modificationDate,
  version: content.version,
  slots: content.slots || [],
  canBeDeletedOrDuplicated: content.canBeDeletedOrDuplicated,
})

export default class ContentService {
  public static getIsStoryContent(content: IContent) {
    return content.type === EContentType.STORY_CONTENT
  }

  public static getIsRecommendableContent(content: IContent) {
    return content.type && [EContentType.STORY_CONTENT, EContentType.CAROUSEL_CONTENT].includes(content.type)
  }

  public static getTypePlaceholder(content: TContent): string {
    const subTypology = 'subTypology' in content && (content.subTypology as TContentSubtypology)

    return ContentService.getTypePlaceholderByTypologies(content.typology, subTypology)
  }

  public static getTypePlaceholderByTypologies(
    contentTypology: EContentTypology,
    contentSubtypology?: TSubtypology
  ): string {
    const subTypologyLabel =
      contentSubtypology && ContentService.getSubTypologyLabel(contentTypology, contentSubtypology)

    return subTypologyLabel || i18n.t(`content.typology.${contentTypology}`)
  }

  public static getSubTypeOrSubTypology(content: TContent): string {
    if (EContentType.CAROUSEL_CONTENT === content.type && content.containerSubType) {
      return i18n.t(`container.subTypeContainer.${content.containerSubType}`)
    }

    if (EContentType.PAGE_CONTENT === content.type && content.containerSubType) {
      return i18n.t(`container.subType.${content.containerSubType}`)
    }

    if (
      [EContentTypology.VIDEO, EContentTypology.FEED, EContentTypology.AUDIO, EContentTypology.BOOK_VARIANT].includes(
        content.typology
      ) &&
      content.subTypology
    ) {
      return i18n.t(`content.typology.${content.typology}_${content.subTypology}`)
    }

    return undefined
  }

  public static getSubTypeOrSubTypologySelectOptions(content: TContent): ISelectWrapperOption[] {
    if (content.typology === EContentTypology.CAROUSEL) {
      return Object.keys(EContainerSubTypeCarousel).map((type) => ({
        value: type,
        optionContent: i18n.t(`container.subTypeContainer.${type}`),
      }))
    }

    if (content.typology === EContentTypology.PAGE) {
      return Object.keys(EContainerSubTypePage)
        .map((type) => ({
          value: type,
          optionContent: i18n.t(`container.subType.${type}`),
        }))
        .filter((type) => type.value !== EContainerSubTypePage.HOME)
    }
    return []
  }

  public static isSubTypeEditable(content: TContent): boolean {
    return (
      content.typology === EContentTypology.CAROUSEL ||
      (content.typology === EContentTypology.PAGE &&
        'subTypology' in content &&
        content.subTypology !== EPageSubTypology.HOME &&
        content.subTypology !== EPageSubTypology.CATCH_UP)
    )
  }

  public static isVideoParentShow(content: TContent): boolean {
    return (
      content.typology === EContentTypology.VIDEO_SHOW &&
      'subTypology' in content &&
      content.subTypology === EVideoParentSubTypology.SHOW
    )
  }

  public static getVideoParentSubTypology(content: TContent): EVideoParentSubTypology | undefined {
    if (content.typology === EContentTypology.VIDEO) {
      if (content.videoSeason) {
        return content.videoSeason.videoShow?.subTypology
      } else {
        return content.videoShow?.subTypology
      }
    }

    return undefined
  }

  public static getContentType(content: TContent): EContentType {
    if (content.typology === EContentTypology.EXTERNAL_LINK) {
      return 'subTypology' in content && content['subTypology'] === EExternalLinkSubTypology.SPONSOR
        ? EContentType.SPONSOR_CONTENT
        : EContentType.EXTERNAL_LINK_CONTENT
    }
    if (content.typology === EContentTypology.STORY) {
      return 'subTypology' in content && content['subTypology'] === EStorySubTypology.RECIPE
        ? EContentType.STORY_RECIPE_CONTENT
        : EContentType.STORY_CONTENT
    }

    return content.type
  }

  public static getSubTypologyLabel(typology: EContentTypology, subTypology: TSubtypology): string | undefined {
    if (typology === EContentTypology.VIDEO_SHOW) {
      return ContentService.getVideoParentSubTypologyLabel(subTypology)
    } else if (typology === EContentTypology.EXTERNAL_LINK) {
      return ContentService.getExternalLinkSubTypologyLabel(subTypology)
    } else if (typology === EContentTypology.STORY && subTypology === EStorySubTypology.RECIPE) {
      return i18n.t(`recordType.STORY_RECIPE`)
    }
  }

  public static getVideoParentSubTypologyLabel(subTypology: TSubtypology): string | undefined {
    if (!subTypology) {
      return
    }
    switch (subTypology) {
      case EVideoParentSubTypology.MOVIE:
        return i18n.t(`recordType.VIDEO_PARENT_MOVIE`)
      case EVideoParentSubTypology.DOCUMENTARY:
        return i18n.t(`recordType.VIDEO_PARENT_DOCUMENTARY`)
      case EVideoParentSubTypology.PERFORMANCE:
        return i18n.t(`recordType.VIDEO_PARENT_PERFORMANCE`)
      case EVideoParentSubTypology.SHOW:
        return i18n.t(`recordType.VIDEO_PARENT_SHOW`)
      default:
        return
    }
  }

  public static getExternalLinkSubTypologyLabel(subTypology: TSubtypology): string | undefined {
    switch (subTypology) {
      case EExternalLinkSubTypology.PLAIN:
        return i18n.t('content.typology.EXTERNAL_LINK')
      case EExternalLinkSubTypology.SPONSOR:
        return i18n.t('content.typology.SPONSOR')
      default:
        return
    }
  }

  public static getVideoParentGenres(genres?: string[]): string {
    if (!genres || genres.length < 1) {
      return undefined
    }

    const genresLabels: string[] = []

    genres.forEach((genre) => {
      if (!genre) {
        return
      }
      genresLabels.push(this.getGenreLabel(genre))
    })

    return genresLabels.join(', ')
  }

  public static getGenreLabel(genre: string) {
    if (!genre) {
      return i18n.t(`videoParentGenres.undefined`)
    }
    const contentBrightcoveIllicoGenresEnumEntry = Object.entries(EContentBrightcoveIllicoGenres).find((value) =>
      value.includes(genre)
    )

    if (contentBrightcoveIllicoGenresEnumEntry) {
      return i18n.t(`videoParentGenres.${contentBrightcoveIllicoGenresEnumEntry[0]}`)
    }
    const contentBrightcoveGenresEnumEntry = Object.entries(EContentBrightcoveGenres).find((value) =>
      value.includes(genre)
    )

    if (contentBrightcoveGenresEnumEntry) {
      return i18n.t(`videoParentGenres.${contentBrightcoveGenresEnumEntry[0]}`)
    }
    return genre
  }

  public static getOriginSystem(content: IContent): EContentOriginSystem | null {
    // TODO: PFU-16319 PFU-18410 remove check a track properly receives identifications
    if (!content.identifications) {
      return null
    }

    const originSystemEntry = Object.entries(content.identifications).filter((value) => value[1].originSystem)
    return originSystemEntry.length > 0 ? (originSystemEntry[0][0] as EContentOriginSystem) : null
  }

  public static getScheduleStartDate(content: TContent) {
    if (content.type === EContentType.VIDEO_RECORDING || content.type === EContentType.VIDEO_STREAM) {
      return content.brightcoveProperties?.schedule?.startsAt
    }

    return undefined
  }

  public static getScheduleEndDate(content: TContent) {
    if (content.type === EContentType.VIDEO_RECORDING || content.type === EContentType.VIDEO_STREAM) {
      return content.brightcoveProperties?.schedule?.endsAt
    }

    return undefined
  }

  public static setTaxonomyCategories(content: IContent, category: string, values: string[]) {
    if (!content.categories) {
      content.categories = {}
    }
    content.categories[category] = values
  }

  public static getRelationshipByType(content: IContent, relationshipType: ERelationshipType): TRelationship[] {
    if (!content.relationships) {
      return null
    }
    return content.relationships.filter((rel) => rel.type === relationshipType)
  }

  public static addRelationship(content: IContent, relationship: TRelationship): void {
    if (!content.relationships) {
      content.relationships = []
    }
    content.relationships.push(relationship)
  }

  public static updateRelationship(content: IContent, type: ERelationshipType, newRelationship: TRelationship) {
    if (!content.relationships) {
      content.relationships = []
    }

    const relationshipIndex = content.relationships.findIndex((relationship) => relationship.type === type)
    if (relationshipIndex !== -1) {
      content.relationships[relationshipIndex].qualifier = newRelationship.qualifier
      content.relationships[relationshipIndex].content = newRelationship.content
    } else {
      ContentService.addRelationship(content, newRelationship)
    }
  }

  public static getSeasonNumber(content: TContent) {
    return ContentService.getTextContentWithType(content, ERelationshipType.SEASON_NUMBER)
  }

  public static getEpisodeNumber(content: TContent) {
    return ContentService.getTextContentWithType(content, ERelationshipType.EPISODE_NUMBER)
  }

  public static getOriginAccountName(content: IContentVideo) {
    return content.originBrightcoveAccountName ? ` (${content.originBrightcoveAccountName})` : ''
  }

  public static getTransitAccountName(content: IContentVideo) {
    return content.brightcoveAccountName ? ` (${content.brightcoveAccountName})` : ''
  }

  public static getAddedAssociations(initialAssociations: IContentCard[], formAssociations: IContentCard[]) {
    return formAssociations.filter((formAsso) => !initialAssociations.some((initAsso) => initAsso.id === formAsso.id))
  }

  public static getRemovedAssociations(initialAssociations: IContentCard[], formAssociations: IContentCard[]) {
    return initialAssociations.filter((initAsso) => !formAssociations.some((formAsso) => formAsso.id === initAsso.id))
  }

  public static getContentAssociationToSend(content: IContent, updatedAssociations: IContentCard[]) {
    const result: IAssociation[] = []
    updatedAssociations.forEach((item) =>
      result.push({
        originId: content.id.toString(),
        destinationId: item.id.toString(),
      })
    )

    return result
  }

  public static hasNewAssociations(initialAssociations: IContentCard[], updatedAssociations: IContentCard[]): boolean {
    for (const updatedAssociation of updatedAssociations) {
      if (!ContentService.hasAssociation(updatedAssociation, initialAssociations)) {
        return true
      }
    }
    return false
  }

  public static hasAssociation(association: IContentCard, associations: IContentCard[]) {
    return (
      associations.filter((item) => {
        return item.id === association.id
      }).length > 0
    )
  }

  public static getShowName(content: TContent) {
    return ContentService.getTextContentWithType(content, ERelationshipType.SHOW_NAME)
  }

  public static getBrightcoveId(content: IContentVideo) {
    return content.brightcoveProperties?.brightcoveId
  }

  public static getDescription(content: IContent, type: ERelationshipType) {
    if (!content.relationships) {
      return null
    }

    const longDesc = content.relationships
      .filter((rel) => rel.type === type)
      .filter((rel) => rel.content.type === EContentType.TEXT_CONTENT)

    return longDesc.length ? (longDesc[0].content as IContentText).text : null
  }

  public static getLongDescription(content: IContent) {
    if (!content.relationships) {
      return null
    }

    const longDesc = content.relationships
      .filter((rel) => rel.type === ERelationshipType.DESCRIPTION && rel.qualifier === ERelationshipQualifier.LONG)
      .filter((rel) => rel.content.type === EContentType.TEXT_CONTENT)

    return longDesc.length ? (longDesc[0].content as IContentText).text : null
  }

  public static getLinkToDisplay(content: TContent, tabContext: ETabContext) {
    if (this.canDisplayLinkValue(content)) {
      return {
        label: i18n.t('detailPanel:contentCardGeneric.label.link'),
        value: this.getLinkValue(content, tabContext),
        placeholder: i18n.t('detailPanel:contentCardGeneric.label.linkAria'),
      }
    }
    return undefined
  }

  public static getVideoPreview(content: TContent, format: 'horizontal' | 'vertical'): IContentVideoPreview {
    if (
      content.typology === EContentTypology.VIDEO &&
      (content as IContentVideo).subTypology === EVideoSubTypology.PREVIEW
    ) {
      return undefined
    }

    if (format === 'horizontal') {
      return content.videoPreviewHorizontal
    }

    if (format === 'vertical') {
      return content.videoPreviewVertical
    }
    return undefined
  }

  private static canDisplayLinkValue(content: TContent) {
    return content.absoluteUrl || [EContentTypology.BOOK_VARIANT].includes(content.typology) || content.slug
  }

  private static getLinkValue(content: TContent, tabContext: ETabContext) {
    if (content.typology === EContentTypology.BOOK_VARIANT) {
      return content.qublivreUrl
    }

    const tenant =
      tabContext && content.tenants?.some((value) => value.toString() === tabContext.toString())
        ? tabContext.toString()
        : content.owner?.toString()

    const tenantDomain = config.tenantDomain[tenant]
    if (tenantDomain) {
      return `${tenantDomain}${content.bypassEmbargoUrl ? content.bypassEmbargoUrl : content.slug}`
    }
    return content.bypassEmbargoUrl
      ? content.absoluteUrl?.replace(content.slug, content.bypassEmbargoUrl)
      : content.absoluteUrl
  }

  private static getTextContentWithType(content: IContent, type: ERelationshipType) {
    const relationship = ContentService.getRelationshipByType(content, type)

    if (relationship?.length) {
      const textContent =
        type === ERelationshipType.SHOW
          ? relationship[0].content.relationships
          : relationship.filter((rel) => rel.content.type === EContentType.TEXT_CONTENT)
      if (textContent?.length) {
        return (textContent[0].content as IContentText).text
      }
    }

    return null
  }

  public static getIsTitleModified(content: IContent): boolean {
    if (content.type !== EContentType.VIDEO_RECORDING) {
      return false
    }

    const contentOriginNameRelationship = content.relationships.find(
      (relationship) => relationship.type === ERelationshipType.ORIGIN_NAME
    ) as undefined | IOriginNameRelationship

    if (!contentOriginNameRelationship) {
      return false
    }

    return content.name !== contentOriginNameRelationship.content.text
  }
}

export const getCardFromContent = (content: IContent): IContentCard => {
  return {
    id: String(content.id),
    name: content.name,
    cardType: ECardType.CONTENT,
    type: content.type,
    status: content.state,
    source: content.source,
    recordType: undefined,
    typology: content.typology,
    originSystem: ContentService.getOriginSystem(content),
    owner: content.owner,
    slug: content.slug,
  }
}

export const getCardFromAssocation = (association: IContent): IContentCard => {
  return {
    id: String(association.id),
    name: association.name,
    cardType: ECardType.CONTENT,
    type: association.type,
    status: null,
    source: association.source,
    recordType: undefined,
    typology: association.typology,
    originSystem: null,
    owner: association.owner,
    slug: association.slug,
  }
}

export const getMenuCardFromMenuReference = (menuReference: IMenuReference): IContentCard => {
  return {
    cardType: ECardType.CONTENT,
    type: EContentType.MENU,
    typology: EContentTypology.MENU,
    status: EContentState.ACTIVE,
    source: EContentSource.QUB,
    recordType: ERecordType.MENU,
    originSystem: EContentOriginSystem.PFU,
    id: menuReference.id.toString(),
    name: menuReference.title,
    owner: menuReference.owner,
    slug: menuReference.slug,
  }
}

export const isContentSourceOrChannel = (content?: {
  recordType?: string
  typology?: EContentTypology
  subTypology?: TSubtypology
}) => {
  if (!content) {
    return false
  }

  if (content.recordType) {
    return content.recordType === 'FEED_SOURCE' || content.recordType === 'CHANNEL'
  }

  if (content.typology) {
    return (content.typology === 'FEED' && content.subTypology === 'SOURCE') || content.typology === 'CHANNEL'
  }

  return false
}

export const getTenantSharedOwners = (tenant: ETenant) => {
  switch (tenant) {
    case ETenant.QUB:
      return [EContentOwner.INFO, EContentOwner.LIVRE, EContentOwner.RADIO]
    case ETenant.JDQ:
      return [EContentOwner.JDQ, EContentOwner.RADIO, EContentOwner.LIVRE]
    case ETenant.JDM:
      return [EContentOwner.JDM, EContentOwner.RADIO, EContentOwner.LIVRE]
    case ETenant.TVAN:
      return [EContentOwner.TVAN, EContentOwner.RADIO, EContentOwner.LIVRE]
    case ETenant.TVAPLUS:
      return [EContentOwner.TVAPLUS, EContentOwner.RADIO]
    case ETenant.TVAS:
      return [EContentOwner.TVAS, EContentOwner.RADIO, EContentOwner.LIVRE]
    case ETenant.CLUB_ILLICO:
      return [EContentOwner.CLUB_ILLICO]
    case ETenant.COUP_DE_POUCE:
      return [EContentOwner.COUP_DE_POUCE]
    default:
      return []
  }
}

export const getSharedOwners = (tenants: ETenant[]) => {
  if (!tenants) {
    return []
  }
  const sharedOwners: EContentOwner[] = []
  tenants.forEach((tenant) =>
    getTenantSharedOwners(tenant).forEach((sharedOwner) => {
      if (!sharedOwners.includes(sharedOwner)) {
        sharedOwners.push(sharedOwner)
      }
    })
  )
  return sharedOwners
}
